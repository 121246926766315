import styled from 'styled-components';
import { calcPxToRem, setTypography, setVh } from 'utils/styles/mixins';
import { colors, rfs } from 'utils/styles/theme';

export const Bg = styled.div`
  /* background-color: ${colors.comet}; */
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: ${setVh(100)};
`;

export const LayoutTitle = styled.div`
  width: 100vw;
  height: ${setVh(100)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: clamp(16px, 10%, 180px);
  padding-left: clamp(16px, 10%, 180px);
`;

export const Title = styled.div`
  color: ${colors.pattensBlue};
  ${setTypography('heading2')}
  ${rfs(`${calcPxToRem(40)}rem`, `margin-bottom`)}
  a {
    ${setTypography('heading5')}
  }
`;
